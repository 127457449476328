<template>
  <div>
    <div class="popup massage">
      <div class="popup__back" @click="closePopup"></div>
      <div class="popup__content">
        <div class="popup__exit" @click="closePopup"><i class="fa fa-times"></i></div>
        <div class="popup__title">
          Історія переадресації
        </div>
        <table class="forwarding">
          <tr>
            <th>
              Номер
            </th>
            <th>
              Час очікування
            </th>
            <th>
              Час розмови
            </th>
            <th>
              Статус
            </th>
          </tr>
          <tr v-for="(item, index) in forwardingList" :key="index">
            <td>
              {{item.internalNumber}}
            </td>
            <td>
              {{item.waitsec}} сек.
            </td>
            <td>
              {{item.billsec}} сек.
            </td>
            <td>
              {{showItemDescriptionLang(item.disposition)}}
            </td>
          </tr>
        </table>
      </div>
    </div>
  </div>
</template>
<script>
  export default {
    props:['forwardingList'],
    methods:{
      closePopup(){
        this.$emit('toggleForwardingPopup', false)
      },
      showItemDescriptionLang(item){
        return item === 'BUSY' ? 'Зайнято' : item === 'ANSWER' ? 'Відповідь' : item === 'CANCEL' ? "Відміна" : item === 'CHANUNAVAIL' ? "Невірний номер" : item === 'NOANSWER' ? 'Немає відповіді' : item === 'CONGESTION' ? 'Лінія перезавантажена' : item;
      },
    }
  }
</script>
<style scoped lang="scss">
.forwarding{
  tr{
    th{
      padding: 3px 10px;
      background-color: #e4e6ee;
    }
    td{
      font-size: 12px;
      padding: 3px 10px;
      background-color: #eeeff3;
      border-bottom: 1px solid #d6d9e0;
    }
    &:last-child{
      td{
        border-bottom: 0;
      }
    }
  }
}
</style>
